import React from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"
import { getProductUrl, formatCurrency } from '../../../util/format'
import BlockContent from "@sanity/block-content-to-react"
import style from './index.module.css'

function ProductListItem (props) {
  return (
    <article key={props.id}>
      <div className={style.product}>
        {(props.overviewImage && props.overviewImage.asset) &&
          <div>
            <Link to={getProductUrl(props.slug.current)}>
              <Img fluid={props.overviewImage.asset.fluid} alt={props.name} />
            </Link>
          </div>
        }
        <div>
          <h2 className={style.name}><Link to={getProductUrl(props.slug.current)} className={style.titleLink}>{props.name}</Link></h2>
          {props._rawShortDescription &&
            <div className="portfolio-list__item__description">
              <BlockContent blocks={props._rawShortDescription} />
            </div>
          }
          <div className={style.price}>{formatCurrency(props.price * 1.21)}</div>
          <Link className={style.link} to={getProductUrl(props.slug.current)}>Bekijk dit product</Link>
        </div>
    </div>
  </article>
  )
}

export default ProductListItem