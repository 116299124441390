import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from '../../layouts'
import style from './index.module.css'
import ProductListItem from "../../components/Product/ListItem"
import BlockContent from "@sanity/block-content-to-react"

const PageShop = ({ data }) => {
  const page = data.page;
  return (
    <Layout>
      <Helmet>
        <title>{page.title} - Sureventure Camperbouw</title>
        <meta name="description" content={page.metaDescription} />
      </Helmet>
      <section className="panel content-text">
        {page.title &&
          <h1>{page.title}</h1>
        }
        {page._rawIntro &&
          <div className="intro">
            <BlockContent blocks={page._rawIntro} />
          </div>
        }
        {page._rawBody &&
          <BlockContent blocks={page._rawBody} />
        }
      </section>

      <section className={style.container}>
        <div id="lijst" className={style.list}>
          {page.products && page.products.map(function(product, index){
            return (
              <ProductListItem {...product} key={index}/>
            );
          })}
        </div>
      </section>
    </Layout>
  )
}

export default PageShop

export const query = graphql`
  query {
    page: sanityPaginaShop {
      title
      metaDescription
      _rawIntro
      _rawBody
      products {
        id
        slug {
          current
        }
        name
        price
        _rawShortDescription
        overviewImage {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

