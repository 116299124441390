export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function getBlogUrl (slug) {
  return `/blog/${slug}/`
}

export function getProjectUrl (slug) {
  return `/project/${slug}/`
}

export function getModelUrl (slug) {
  return `/model/${slug}/`
}

export function getProductUrl (slug) {
  return `/shop/${slug}/`
}

export function formatCurrency(num) {
  let currencyString = (num / 100)
     .toFixed(2)
     .replace(".", ",") // replace decimal point character with ,
     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
  currencyString = "€ " + currencyString;
  return currencyString;
}